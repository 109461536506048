body {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  background-color: rgb(17, 24, 39);
  color: white;
}


#softwareBox {
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  margin-top:20px;
  background-color: black;
  border-radius: 10px;
}

#software {  
  margin:10px;
  background-color: rgb(19, 19, 19) ;
  padding: 10px;
  width: 200px;
  text-align: center;
  border-radius: 10px;
}

#nameSoftware {
  font-weight: bold;
}

#divNavbar {
  background-color: rgb(10, 7, 37);

}

#navBox{
  display: flex;
  align-items: center;
}

#logoSize{
max-height:25px;
margin:10px;
cursor: pointer;
}

#linkedIn{
  height:50px;
  cursor: pointer;
}

#socialConnections{
  display: flex;
  justify-content: center;
}
/*
width: 200px;
padding: 10px;
margin: 10px;
background-color: aliceblue;
cursor: pointer;
*/
